<template>
    <div class="managments-list">
        <div class="managments-list-title">
            <h2>{{dep.name}}</h2>
        </div>
        <div class="managments-list-filters">
            <el-input v-model="search" style="width: 240px" placeholder="חיפוש" />
            <div @click="$emit('back_from_managments_list')" class="back-btn">
              <i class="material-icons">arrow_back</i>
            </div>
        </div>
        <div class="managments-list-content">
            <h2 style="color: red; text-align: center;" v-if="!filter_by_search.length" >לא קיימות רשומות</h2>
            <template v-for="managment in filter_by_search" :key="managment.created_at">
                <div class="managments-list-content-managment">
                    <h4 v-if="platform == 'mobile'">{{managment.name}}</h4>
                    <h2 v-else>{{managment.name}}</h2>
                    <el-button @click="selected_managment = managment" size="small" round  type="primary">צפייה</el-button>
                </div>
            </template>
        </div>

        <DisplayManagment 
            v-if="selected_managment"
            :managment="selected_managment"
            @close="selected_managment = null"
        />
    </div>
</template>

<script>
import { ref, computed, defineAsyncComponent } from 'vue'
import store from '../../../../store'
export default {
    emits:['back','back_from_managments_list'],
    props:['dep'],
    components:{
        DisplayManagment: defineAsyncComponent(() => import('./DisplayManagment.vue'))
    },
    setup (props,_) {
        
        const selected_managment = ref(null)

        const search = ref('')

        const filter_by_search = computed(() => {
            if(!search.value) return props.dep.managements
            return props.dep.managements.filter(m => {
                return m.name.includes(search.value)
            })
        })

        const platform = ref(computed(()=>{
            return store.state.platform
        }))

        return {
            search,
            platform,
            selected_managment,
            filter_by_search
        }
    }
}
</script>

<style scoped>
    .managments-list{
        width: 100%;
        height: 100%;
        color: #fff;
    }
    .managments-list-filters,.managments-list-title{
        width: 100%;
        height: 50px;
        display: flex;
        align-items: center;
    }
    .managments-list-filters{
        justify-content: space-between;
    }
    .managments-list-title{
        justify-content: center;
    }
    .managments-list-content{
        width: 100%;
        height: calc(100% - 100px);
        overflow-y: auto;
    }
    .managments-list-content-managment{
        width: 100%;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background: #fff;
        border-radius: 10px;
        padding: 0 10px;
        margin-bottom: 5px;
        color: #333;
    }
    .back-btn{
        width: 30px;
        height: 30px;
        border-radius: 50%;
        cursor: pointer;
        user-select: none;
        display: flex;
        justify-content: center;
        align-items: center;
        background: red;
        margin: 0 5px;
    }
</style>